
$logo-primary: #0b695e ;
$logo-primary_light : rgba(11, 105, 94, 0.17);


$logo-primary-gradient : linear-gradient(90deg, #4750f6 0%, #fb6331 100%);


$logo-primary-gradient-huge : linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89deg, #4750f6, #fb6331);

$panel-bg : #fcf9ff;