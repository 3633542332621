@import "./variables.scss";

.main-app-root{
    .main-app-header{
        padding-top: 0;
        h3{
            text-align: center;
            font-size:35px;
            font-weight: 900;
            color: #303858;
        }
    }
    .main-app-greeting-header{
        
        .main-app-greeting-header-card{
            padding:30px;
            text-align: center;
            h3{
                font-size: 22px;
                span{
                    color: $logo-primary;
                    font-weight: 900;
                    letter-spacing: 1.2px;
                    display: inline-block;
                    margin-left: 5px;
                    font-size: 28px;
                }
            }
            h6{
                color: gray;
                letter-spacing: 1px;
                font-size:14px;
                margin-top:10px;
            }
        }
    }
    .summary-card-grid{
        .summary-card{
            padding: 30px 20px;
            .summary-card-left{
                align-items:flex-start;
                gap: 8px;
                h3{
                    color: #a0a0a0db;
                    font-size: 17px;
                    font-weight: 900;
                }
                .summary-card-count{
                    h3{
                        font-size: 45px;
                        color: #303858;
                        padding: 0;
                    }
                    span{
                        padding: 0;
                        display: inline-block;
                        margin-top:10px;
                        font-weight: 900;
                        // letter-spacing: 0.9px;
                        color: #a0a0a0db;
                    }
                }
            }
            .summary-card-right{
                padding: 15px;
                // background: #d25e5da3;
                // background: rgb(255, 199, 182);
                background: $logo-primary_light;
                border-radius: 50%;
                height: 60px;
                width: 60px;
                margin-top:-40px;

            }
        }
    }
    .table-container{
        .ticket-table{
            thead{
                background: $logo-primary_light;
                th{
                    font-size: 16px;
                    &:nth-child(1){ min-width: 150px; }
                    &:nth-child(2){ min-width: 150px; }
                    &:nth-child(3){ min-width: 180px; }
                    &:nth-child(4){ min-width: 200px; }
                    &:nth-child(5){ min-width: 150px; }
                    &:nth-child(6){ min-width: 180px; }
                    &:nth-child(7){ min-width: 180px; }
                    &:nth-child(8){ min-width: 100px; }
                    &:nth-child(9){ min-width: 100px; }
                }

            }
            tbody{
                td{
                    font-size: 14px;
                }
            }
        }
    }
    .customer-ticket-table-container{
        .customer-ticket-table{
            thead{
                background: $logo-primary_light;;
                th{
                    font-size: 16px;
                    &:nth-child(1){ min-width: 150px; }
                    &:nth-child(2){ min-width: 150px; }
                    &:nth-child(3){ min-width: 180px; }
                    &:nth-child(4){ min-width: 200px; }
                    &:nth-child(5){ min-width: 150px; }
                    &:nth-child(6){ min-width: 180px; }
                    &:nth-child(7){ min-width: 180px; }
                    &:nth-child(8){ min-width: 100px; }
                    &:nth-child(9){ min-width: 100px; }
                }

            }
            tbody{
                td{
                    font-size: 14px;
                }
            }
        }
    }


}
.category-table{
    thead{
        background: $logo-primary_light;
        th{
            font-weight: 900;
        }
    }
}
.message-back-btn{
    position: absolute;
    left: 10px;
    font-weight: 900;
    letter-spacing: 1px;
    top:-200px;


    border: solid 1px transparent;
    background: $logo-primary_light;
    // background-clip: content-box, border-box;
    // background-clip: unset;
    /* padding: 0.5rem 3rem; */
    box-shadow: 2px 37px 0px $panel-bg inset;
    color: $logo-primary;
    &:hover{
        color: $panel-bg;
    }


    &.category-back{
        position: unset;
        left: 115px;
        top:40px;
        margin: 10px 0 20px 0;
    }
}
.message-section{
    position: relative;

    .message-card{
        border: none;
        border: 1px solid rgba(128, 128, 128, 0.29);
        // border-right:none;
        
        box-shadow: none;
        
        .message-card-content{
            // background: #032b4f;
            h5{
                color: white;
            }
        }
        .message-card-header-container{
            background: $logo-primary_light;
            .message-card-header-icon{
                padding:15px;
                // border: 3px solid #0c61a6;
                border-radius: 5px;
                .icon-holder{
                    padding:6px;
                    border: 4px solid #0c61a6;
                    border-radius: 5px;
                }
                svg{
                    font-size: 40px;
                    color: #0c61a6;
                    margin-left: 0!important;
                }
            }
        }
        .message-card-header{
            // padding: 10px;
            color: #0c61a6;
            font-weight: 900;
            font-size: 21px;
            margin-left: 0px;
            margin-bottom: 0;
            span{
                color : gray;
                display: inline-block;
                font-size: 14px;
            }
            
        }
        .text-box-container{
            background-color: white;
            padding: 0;
            border-radius: 10px;
            position: relative;
            display: flex;
            border: 1px solid #e8c8bf;
            .text-box-left-section{
                flex: 0 0 calc( 100% - 100px );
                max-width :calc( 100% - 100px );
                fieldset{
                    border: none;
                }
                .MuiInputBase-formControl.MuiInputBase-multiline{
                    border-radius: 50%;
                }
            }
            .text-box-right-section{
                flex: 0 0 100px;
                max-width: 100px;
                display: flex;
                align-items: center;
                .text-box-btn-holder{
                    position: absolute;
                    .button{
                        background: #092b4f;
                        min-width: unset;
                        height: 40px;
                        width: 40px;
                        padding: 12px;
                    }
                    .attach-icon-btn{
    
                    }
                    .send-icon-btn{
                        background: rgb(38, 115, 80);
                    }
                }
            }
            .MuiFormHelperText-root.Mui-error{
                position: absolute;
                top:14px;
            }
        }
    }
    .message-info-card{
        border: none;
        border: 1px solid rgba(128, 128, 128, 0.29);
        box-shadow: none;
        min-height: 100%;
        .message-info-card-header-container{
            background: $logo-primary_light;
            .message-info-card-header-icon{
                padding:15px;
                // border: 3px solid #0c61a6;
                border-radius: 5px;
                .info-icon-holder{
                    padding:6px;
                    border: 4px solid #0c61a6;
                    border-radius: 5px;
                }
                svg{
                    font-size: 40px;
                    color: #0c61a6;
                    margin-left: 0!important;
                }
            }
        }
        .message-info-card-header{
            // background: rgba(199, 230, 255, 0.56);
            // text-align: center;
            // padding: 10px;
            color: #0c61a6;
            font-weight: 900;
            
        }
    }
}
.primary-grad-btn{
    // background: $logo-primary-gradient;
    // background: $logo-primary-gradient;
    background: $logo-primary;
    letter-spacing: 1px;
}

@media screen and (min-width: 1500px){
    .container{
        max-width:85%;
        margin: 0 auto;
        .main-app-root-container{
            padding: 0;
            padding-top: 30px;
            box-shadow: none;
        }
    }
    .main-app-root{
        .MuiCard-root{

        }
        .main-app-header{
            padding-top: 0;
            h3{
            }
        }
        .main-app-greeting-header{
            
            .main-app-greeting-header-card{

                h3{
                    span{
                    }
                }
                h6{
                }
            }
        }
        .summary-card-grid{
            .summary-card{
                .summary-holder{
                    gap: 80px;
                }
                .summary-card-left{
                    h3{
                        margin-bottom:10px;
                        // font-size: 20px;
                    }
                    .summary-card-count{
                        h3{
                            font-size: 55px;
                            margin-bottom: 0;
                        }
                        span{
                            margin-top: 20px;
                            // font-size: 17px;
                        }
                    }
                }
                .summary-card-right{
                }
            }
        }
    }
       
}

@media screen and (min-width: 1800px){
    .main-app-root{
        .MuiCard-root{

        }
        .main-app-header{
            padding-top: 0;
            h3{
            }
        }
        .main-app-greeting-header{
            
            .main-app-greeting-header-card{

                h3{
                    span{
                    }
                }
                h6{
                }
            }
        }
        .summary-card-grid{
            .summary-card{
                .summary-holder{
                    gap: 80px;
                }
                .summary-card-left{
                    h3{
                        // font-size: 20px;
                    }
                    .summary-card-count{
                        h3{
                            // font-size: 55px;
                        }
                        span{
                            margin-top: 20px;
                            // font-size: 17px;
                        }
                    }
                }
                .summary-card-right{
                    height: 80px;
                    width:80px;
                    padding:20px;
                }
            }
        }
    }
       
}